import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'termofservices',
  templateUrl: './termsOfServices.component.html',
  styleUrls: ['./termsOfServices.component.scss'],
})
export class TermOfServicesComponent implements OnInit, OnDestroy {
  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
