import { Injectable } from '@angular/core';

@Injectable()
export class AppSearchService {
  datatableSearch(data: any[], columnList: any[], serchValue: string): any[] {
    let resultData = [];
    if (serchValue != '' && serchValue.length >= 2) {
      if (columnList && columnList.length > 0 && serchValue != '') {
        columnList.forEach((col: any) => {
          data.forEach((data: any, index: any) => {
            if (data) {
              let x;
              if (col.dataKey.includes('.')) {
                x = this.sortInnerFunc(col.dataKey, data);
                if (typeof x == 'string') {
                  x != null ? (x = x.toLowerCase()) : (x = null);
                }
              } else {
                x = data[col.dataKey];
                if (typeof x == 'string') {
                  x != null
                    ? (x = x.toLowerCase().replace(/\s/g, ''))
                    : (x = null);
                  if (
                    x &&
                    x.includes(serchValue.toLowerCase().replace(/\s/g, ''))
                  ) {
                    resultData[index] = data;
                    data[index] = null;
                  }
                } else {
                  if (x == serchValue) {
                    resultData[index] = data;
                    data[index] = null;
                  }
                }
              }
            }
          });
        });
        resultData = resultData.filter(() => {
          return true;
        });
      } else {
        resultData = data;
      }
    } else {
      resultData = data;
    }
    return resultData;
  }

  private sortInnerFunc(temp: string, data: any) {
    let value;
    let i = 0;
    const strarr = temp.split('.');
    for (i = 0; i < strarr.length; i++) {
      if (i === 0) {
        value = data[strarr[i]];
      } else {
        const tmp = strarr[i];
        value = value[tmp];
        return value;
      }
    }
  }

  sortHandle(gridData:any[], column: any) {
    column.sort = !column.sort;
    const sortData = JSON.parse(JSON.stringify(gridData));
    sortData.sort((a, b) => {
      let x;
      let y;
      if (column.dataKey.includes('.')) {
        x = this.sortInnerFunc(column.dataKey, a);
        y = this.sortInnerFunc(column.dataKey, b);
      } else {
        x = a[column.dataKey];
        y = b[column.dataKey];
      }
      if (typeof x == 'string') {
        x != null ? (x = x.toLowerCase()) : (x = null);
      } else {
        x != null ? x : (x = null);
      }
      if (typeof y == 'string') {
        y != null ? (y = y.toLowerCase()) : (y = null);
      } else {
        y != null ? y : (y = null);
      }

      return (x == y ? 0 : x < y ? -1 : 1) * (column.sort ? 1 : -1);
    });
    return sortData;
  }
}
