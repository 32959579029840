import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AppCookieService } from '../modules/core/services/cookie-service.service';

@Injectable()
export class AdminauthGuardService implements CanLoad {
  constructor(
    private router: Router,
    public _appCookieService: AppCookieService
  ) {}

  canLoad(route: Route): boolean {
    const role = this._appCookieService.getCookieData().role;

    const accesstoken = this._appCookieService.getCookieData().accesstoken;

    if (accesstoken) {
      if (parseInt(role) == 0) {
        return true;
      } else if (role != '') {
        setTimeout(() => {
          this._appCookieService.clearCookieData();
          this.router.navigate(['login']);
          alert('You are not authorised to visit this page');
          return false;
        }, 0);
      }
    } else {
      if (window.location.href.split('#/')[1] != 'login') {
        this._appCookieService.clearCookieData();
        this._appCookieService.setCookieData(
          'routeUrl',
          JSON.stringify(window.location.href.split('#/')[1])
        );
        this.router.navigate(['login']);
      }
    }
  }
}
