import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCookieService } from 'src/app/modules/core/services/cookie-service.service';
import { environment } from 'src/environments/environment';
import { IResponse } from '../models/response.model';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class TokenService {
  REFRESH_URL = environment.serverDomain + 'getToken';
  constructor(
    private _httpClient: HttpClient,
    private _appCookieService: AppCookieService
  ) {}
  getRefreshToken() {
    const headers = new HttpHeaders().set(
      'RefreshToken',
      this._appCookieService.getCookieData().refreshtoken
    );
    return this._httpClient.get(this.REFRESH_URL, { headers: headers }).pipe(
      tap((res: IResponse) => {
        this._appCookieService.setCookieData(
          'accesstoken',
          res.data['access_token']
        );
        this._appCookieService.setCookieData('jwt', res.data['id_token']);
      })
    );
  }
}
