import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermOfServicesComponent } from './components/termsOfServices/termsOfServices.component';
import { AuthGuardService } from './route-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'userPortal',
    pathMatch: 'full',
    canLoad: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },

  {
    path: 'termOfService',
    component: TermOfServicesComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'userPortal',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'adminPortal',
    loadChildren: () =>
      import('./adminModules/home/home.module').then((m) => m.HomeModule),
  },
  { 
    path: '**',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
