import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AppCookieService } from './../services/cookie-service.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Event, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { IResponse } from '../models/response.model';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  url: string;
  logout = false;
  refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  REFRESH_URL = environment.serverDomain + 'getToken';
  requestArray: any[] = [];

  constructor(
    private _appCookieService: AppCookieService,
    private _router: Router,
    private _httpClient: HttpClient
  ) {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenId = this._appCookieService.getCookieData().accesstoken;
    if (
      !request.url.includes('login') ||
      !request.url.includes('sendResetPasswordLink')
    ) {
      if (
        tokenId &&
        !request.url.includes(environment.assetsRepository) &&
        !request.url.includes(environment.dataRepository) &&
        !request.url.includes(environment.assetsRepository2) &&
        !request.url.includes(environment.dataSample) &&
        !request.url.includes(environment.dataEquip) &&
        !request.url.includes(environment.assetsEqui)
      ) {
        request = request.clone({
          headers: request.headers.set('Authorization', tokenId),
        });
      }
    }
    if (request.url.includes('preSignedUrl')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if ([401, 403].includes(err.status)) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter((result) => result != null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
          } else {
            this.refreshTokenInProgress = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            return this.getRefreshToken().pipe(
              switchMap((res: IResponse) => {
                if (res.status) {
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(res.data['access_token']);
                  return next.handle(this.addAuthenticationToken(request));
                } else {
                  setTimeout(() => {
                    this._appCookieService.clearCookieData();
                    this._router.navigate(['login']);
                    alert('Your session has been expired. Please login again');
                  }, 10);
                }
              })
            );
          }
        }
        return throwError(err.error);
      })
    );
  }

  getRefreshToken() {
    const headers = new HttpHeaders().set(
      'RefreshToken',
      this._appCookieService.getCookieData().refreshtoken
    );
    return this._httpClient.get(this.REFRESH_URL, { headers: headers }).pipe(
      tap(
        (res: IResponse) => {
          if (res.status) {
            this._appCookieService.setCookieData(
              'accesstoken',
              res.data['access_token']
            );
            this._appCookieService.setCookieData('jwt', res.data['id_token']);
          }
        },
        catchError((error) => {
          this._appCookieService.clearCookieData();
          this._router.navigate(['login']);
          alert('Your session has been expired. Please login again');
          return of(false);
        })
      )
    );
  }

  checkRoute() {
    if (this.url.includes('login')) {
      return true;
    } else {
      return false;
    }
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this._appCookieService.getCookieData().accesstoken;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }
    // We clone the request, because the original request is immutable
    return (request = request.clone({
      headers: request.headers.set('Authorization', accessToken),
    }));
  }
}
