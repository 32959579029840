import { Component, HostListener } from '@angular/core';
import { async } from '@angular/core/testing';
import { AppCookieService } from './modules/core/services/cookie-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'gastop-application';
  constructor(private _appCookieService: AppCookieService) {
    if (this._appCookieService.getCookieData().remember != "true" && !sessionStorage.getItem("activeSession") ) {
      this._appCookieService.clearCookieData();
    }
  }
}
