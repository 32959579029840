import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components/card/card.component';
import { PropertyPaneComponent } from './components/property-pane/property-pane.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { CookieService } from 'ngx-cookie-service';
import { AppCookieService } from './services/cookie-service.service';
import { AppSearchService } from './services/search.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlashMessageComponent } from './components/flash-message/flash-message.component';
import { TokenInterceptor } from './helpers/token.interceptor.service';
import { LoaderComponent } from './components/loader/loader.component';
import { TreeComponent } from './components/tree/tree.component';
import { ColumnComponent } from './components/datatable/column.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { DataService } from './components/datatable/datatable.service';
import { TagComponent } from './components/tag/tag.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TokenService } from './services/token.service';

@NgModule({
  declarations: [
    CardComponent,
    PropertyPaneComponent,
    ConfirmationBoxComponent,
    FlashMessageComponent,
    LoaderComponent,
    TreeComponent,
    ColumnComponent,
    DatatableComponent,
    TagComponent,
    DropdownComponent,
    TextInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [
    CardComponent,
    PropertyPaneComponent,
    HighchartsChartModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ConfirmationBoxComponent,
    FlashMessageComponent,
    LoaderComponent,
    TreeComponent,
    ColumnComponent,
    DatatableComponent,
    TagComponent,
    DropdownComponent,
    TextInputComponent,
  ],
  providers: [
    CookieService,
    AppCookieService,
    AppSearchService,
    DataService,
    TokenService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [AppCookieService, AppSearchService, DataService],
    };
  }
}
