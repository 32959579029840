<style>
h5 { font-weight:bold; font-size:13px; } 
p { font-size:12px; } 
.page-height { height:100vh; border:none; padding:40px; }
</style>
<div class="grid-container page-height">

    <h4 class="nameStyle">Term Of Service</h4>
    
    <p>The terms and conditions of sale contained herein (“Agreement”) constitute the entire
    agreement between Gastops Ltd. (“Gastops”) and you (“Customer”). Any terms that are
    inconsistent with this Agreement are hereby disclaimed by Gastops and no form of acceptance,
    except Gastops’ prior written acknowledgement shall constitute a valid acceptance thereof. The
    commencement of the manufacture or delivery of Products (as defined below) by Gastops is for
    the benefit of the Customer only and shall not be deemed an acceptance of the terms and
    conditions contained in the Customer’s order.</p>
    
    <h5>PRODUCTS</h5>
    <p>“Products” shall mean any product or service identified on either (a) Gastops’ proposals,
    quotations or order acknowledgements; or (b) current applicable price lists, in each case
    identifying the applicable Product specification. Gastops shall use best efforts to notify the
    Customer of any alterations to any Product deemed necessary to comply with specifications,
    safety standards or governmental regulations, to make a Product non-infringing with respect to
    any intellectual property or other proprietary interest or to otherwise improve a Product and
    such altered Product shall be deemed fully conforming.</p>
    
    <h5>ORDERS</h5>
    <p>Products shall be purchased by the Customer issuing a written purchase order signed by an
    authorized representative of the Customer, indicating the relevant Gastops’ proposal or
    quotation number, specific Products desired, quantity, total purchase price, shipping
    instructions (including Customer’s Employer Identification Number (EIN) or Federal Tax
    Identification Number (FTIN) for US shipments, Value Added Tax (VAT) number for shipments
    outside North America, ship to address, ship to contact name and phone number, method of
    shipping and carrier account information, billing address (if different from above), applicable tax
    exempt certifications and any other special instructions. All orders are subject to prior written
    acceptance by Gastops.</p>
    
    <h5>PRICES</h5>
    <p>Prices quoted are valid only for the transaction and period of time appearing on the document
    referencing this Agreement, unless otherwise specified by Gastops. Prices are exclusive of all
    taxes, handling or other charges, including insurance, brokerage fees, premiums, duties,
    transportation or special packaging (collectively “Charges”). Any Charge that Gastops may be
    liable to collect on behalf of any governmental authority or otherwise as a result of the sale, use
    or delivery of Products is the responsibility of the Customer, and if paid by Gastops shall be
    charged to the Customer as a separate item on the invoice and the Customer agrees to
    reimburse Gastops in respect thereof.</p>
    
    <h5>TERMS OF PAYMENT</h5>
    <p>Payment terms are thirty (30) days net from the date of shipment, or in the case of services,
    thirty (30) days net from the date of completion, unless otherwise specified by Gastops. Gastops
    reserves the right to obtain prior credit approval and request alternative payment terms, at its
    discretion. If at any time the Customer is delinquent in the payment of any invoice or is
    otherwise in breach of this Agreement, Gastops may, at its discretion withhold shipment or stop
    performance of services in respect of any order and may, at its option require the Customer to
    pre-pay for further shipments or performance. All payments not received when due shall be
    subject to an additional charge of one and a half percent (1.5%) per month (annual rate 18%) of
    the unpaid amount or the maximum rate permitted by law, which ever is less until the date of
    payment. The Customer grants to Gastops a security interest in Products (excluding services)
    purchased under this Agreement to secure payment for those Products purchased. If
    requested by Gastops, the Customer agrees to execute financing statements to perfect such
    security interest. There is no set-off right for the Customer.</p>
    
    <h5>PERFORMANCE AND SHIPPING</h5>
    <p>Gastops shall use best efforts to meet shipping and performance dates specified or
    communicated to the Customer. All Products (excluding services) shall be delivered EXW
    Gastops [Incoterms 2000], unless otherwise specified by Gastops. Title and risk of loss to
    Products (excluding services) shall pass from Gastops to the Customer upon delivery to the
    shipping carrier at the EXW point. All claims for shortage of Products ordered or for incorrect
    charges must be presented to Gastops within ten (10) business days after receipt by the
    Customer of the particular shipment of Products. Gastops shall not be liable for any damages or
    penalties for delay in delivery and the carrier shall not be deemed to be an agent of Gastops.
    Gastops may suspend its delivery obligation upon prior written notice to the Customer and
    without prejudice to any other rights of Gastops under this Agreement should a determination
    be made by Gastops that the financial condition of the Customer is such that the Customer’s
    obligations to Gastops with respect to payment or other material obligation may be
    compromised. Notwithstanding any other provision of this Agreement, Products shall be
    deemed accepted by the Customer upon first use (in the case of products) or upon completion
    (in the case of services), as applicable.</p>
    
    <p>With respect to services, Gastops represents: (a) that it will use qualified individuals with suitable
    training, experience and skill; and (b) that it will perform such services with due diligence in a
    manner consistent with industry standards applied to the performance of similar services.</p>
    
    <h5>PACKAGING</h5>
    <p>All Products shall be packaged for shipment to the Customer in accordance with Gastops’
    standard packaging practices and in conformity with applicable regulations.</p>
    
    <h5>CANCELLATION</h5>
    <p>Any cancellation, termination or issuance of a hold on any Customer order accepted by Gastops,
    by the Customer, shall be upon mutually agreeable terms that will compensate Gastops for any
    incurred costs, including but not limited to any work in progress or services performed and a
    reasonable allocation of general and administrative expenses relating to the Products.</p>
    
    <h5>WARRANTY</h5>
    <p>Gastops’ sole and exclusive obligation to the Customer for any Product (including without
    limitation integral parts thereof not of Gastops’ manufacture but excluding bulbs, fuses or parts
    of glass construction) and excluding Software (as defined and warranted below) and services (as
    warranted below) made by Gastops and sold hereunder are to repair returned Product or
    provide a replacement Product, at Gastops’ sole option, for any Product which has been
    returned to Gastops under the RMA procedure (as defined below) and which in the reasonable
    opinion of Gastops is determined to be defective in workmanship, material or not in compliance
    with the Gastops specification applicable to the Product and has in fact failed under normal use
    on or before one (1) year from the date of original installation or fifteen (15) months from the
    date of original shipment of the Product, which ever period is shorter. During the warranty
    period, any labor or travel costs associated with warranty services performed at the Customer’s
    facility or labor costs incurred by Gastops outside normal working hours shall be at the
    Customer’s sole expense. Replacement accessories or parts are warranted hereunder to be
    free from any material defect in workmanship and materials for a period of thirty (30) days from
    the original date of delivery. All Products, which are experimental products, prototypes or
    products used in field trials, are not warranted. All third parties’ products (including software)
    sold by Gastops carry only the original manufacturer’s warranty applicable to the Customer. Any
    Product repaired or replaced under warranty is only warranted for the period of time remaining
    in the original warranty for the Product. Gastops reserves the right, at its sole discretion, to
    issue a credit note for any defective Product as an alternative to repair or replacement. The
    warranty provided herein shall extend to any Product which has proved defective and has failed
    through normal use, but excludes and does not cover any Products or parts thereof which have
    been accidentally damaged, disassembled, modified, misused, used in applications which exceed
    the Product specifications or ratings, neglected, improperly installed by the Customer or
    otherwise abused or is used in hazardous activities. The Customer must claim under the
    warranty in writing not later than thirty (30) days after the claimed defect is discovered. Gastops
    warrants that services will be performed in a good and workmanlike manner in accordance with
    standards reasonably applicable to the services, and will reperform any services which Gastops
    determines are not in compliance with this warranty which the Customer advises Gastops of in
    writing, on or before thirty (30) days immediately following completion of the applicable service.
    The Customer must make all claims under these warranties and no claim will be accepted from
    any third party.</p>
    
    <h5>WORK ON CUSTOMER’S PREMISES</h5>
    <p>If the furnishing of any Products involves operations by Gastops on the premises of the
    Customer or one of its customers, Gastops shall take all necessary precautions to prevent the
    occurrence of any injury to persons or damage to property, including without limitation following
    any rules, procedures or other requirements. Gastops shall maintain Comprehensive General
    Liability (including contractual liability coverage) and Automobile Liability with suitable limits
    insuring the liabilities assumed above, as well as appropriate Worker’s Compensation Insurance
    as will protect the Customer from all claims under any applicable worker’s compensation acts.
    At the Customer’s request, Gastops shall furnish to Customer a Certificate of Insurance
    completed by its insurance carrier(s) certifying that the above insurance coverages are in effect.</p>
    
    <h5>RETURN MATERIAL AUTHORIZATION (RMA)</h5>
    <p>Gastops will only accept Products returned under the Gastops Return Material Authorization
    process (“RMA”). Customer shall obtain an RMA number from Gastops prior to returning any
    Product and return the Product prepaid and insured to Gastops’ facility. Any Product which has
    been returned to Gastops but which is found to meet the applicable specification for the
    Product and not defective in workmanship and material, may be subject to Gastops’ standard
    examination charge in effect at the time and may be charged to the Customer, at Gastops sole
    discretion.</p>
    
    <h5>SOFTWARE LICENSE AND WARRANTY</h5>
    <p>For software developed by Gastops and contained in any Product and all related documentation
    (collectively “Software”), Gastops does not transfer ownership (which shall remain solely with
    Gastops) but only grants to the Customer a perpetual, non-exclusive license to use the Software
    restricted to use related only to the operation of the single Product. Such license is transferable
    only with the transfer of ownership of the Product in which it is used. Except for making a backup
    copy or as permitted by law, the Customer shall not (a) modify, reproduce, copy, reverse
    engineer, decompile or disassemble all or any portion of the Software; (b) distribute, market,
    disclose rent, lease or create derivative works, or sublicense the use of the Software to any third
    party; or (c) permit or authorize anyone within the Customer’s reasonable control to do any acts
    in A or B above. Gastops warrants that the Software under normal use and service as originally
    delivered to the Customer will function substantially in accordance with the functional
    description set out in the Product specification and/or user manual supplied with the Software
    for a period of ninety (90) days from the date of shipment. Gastops’ sole liability and the
    Customer’s sole remedy for a breach of this Software warranty shall be Gastops’ good faith
    efforts to rectify the non-conformity or, if after repeated efforts Gastops is unable to rectify the
    non-conformity, Gastops shall accept return of the Product containing the non-conforming
    Software and shall refund to the Customer any amounts paid by the Customer in respect
    thereof. This warranty is void if failure of the Software has resulted from accident, misuse,
    abuse, misapplication or modification.</p>
    <p>EXCEPT FOR THE WARRANTIES STATED HEREIN, NO WARRANTY, CONDITION OR REPRESENTATION,
    EXPRESS, IMPLIED, ORAL OR STATUTORY, IS PROVIDED TO THE CUSTOMER OR ANY THIRD
    PARTY, INCLUDING WITHOUT LIMITATION ANY WARRANTY, CONDITION OR REPRESENTATION
    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR SATISFACTORY QUALITY. THE
    CUSTOMER’S SOLE AND EXCLUSIVE REMEDIES HEREUNDER AND THE ONLY LIABILITY OF
    GASTOPS IS EXPRESSLY LIMITED TO THE TERMS OF THIS AGREEMENT.</p>
    
    <h5>INDEMNITY</h5>
    <p>Gastops shall indemnify and hold harmless the Customer, its directors, officers, employees,
    agents, representatives, successors and assigns against any and all claims, demands, judgments,
    damages or liabilities (including personal injury to or death of persons or property damage)
    arising from any negligent act or omission of Gastops, its agents, employees or subcontractors,
    except to the extent attributable to the sole and direct gross negligence of the Customer.
    Notwithstanding the above, in no event shall Gastops be liable to the Customer for any
    incidental, indirect, special, punitive, contingent or consequential damages and Gastops’ total
    cumulative liability to the Customer hereunder shall not exceed the amount paid to Gastops for
    Products furnished to the Customer from which such liability arose. The Customer shall
    indemnify and hold harmless Gastops, its directors, officers, employees, agents, representatives,
    successors and assigns against any and all claims, demands, judgments, damages or liabilities
    (including personal injury to or death of persons or property damage and third party claims)
    arising from or in connection with Customer’s use of any Product furnished by Gastops
    hereunder. Gastops shall defend any suit or proceeding brought against the Customer based
    on a claim that any Product manufactured or furnished by Gastops hereunder constitutes an
    infringement of any patent, provided the Customer provides all reasonable prior notice and all
    necessary authority and assistance to Gastops in defending such claim. If a determination is
    made by a court of competent jurisdiction that Gastops’ Product infringes any third party
    intellectual property rights, Gastops shall either (a) procure for the Customer the right to
    continue using such Product; (b) replace the same modified so that it becomes non-infringing; or
    (c) refund an appropriate portion of the purchase price, in each case at its sole discretion and
    expense. Customer agrees that the forgoing shall constitute Gastops sole and exclusive liability
    to Customer for patent infringement and Gastops shall have no further obligations to Customer
    other than as described herein.</p>
    
    <h5>EXPORT RESTRICTIONS</h5>
    <p>Both parties shall comply with all applicable export laws and regulations. The Customer shall
    not transmit, export or re-export, direct or indirectly, separately or as part of any system, the
    Product or any technical data received from Gastops, without first obtaining a license as may be
    required under such regulations. The Customer shall indemnify and hold harmless Gastops
    from and against any and all damages sustained by the Customer arising from or in connection
    with any breach of this section.</p>
    
    <h5>COMPLIANCE WITH LAWS</h5>
    <p>Gastops warrants that it shall comply with the applicable provisions of all Federal, State, or local
    laws or ordinances and all lawful orders, rules and regulations issued thereunder as they may
    pertain to Gastops performance under this Agreement.</p>
    
    <h5>GOVERNING LAW</h5>
    <p>This Agreement shall be governed by and construed in accordance with the applicable laws of
    the State of New York, excluding conflict of law rules. The parties specifically disclaim the
    application of the United Nations Convention on Contracts for the International Sale of Goods.</p>
    
    <h5>ARBITRATION</h5>
    <p>All matters in difference between the parties in relation to this Agreement may be referred to
    the arbitration of a single arbitrator, if the parties agree upon one, otherwise to three
    arbitrators, one to be appointed by each party and a third to be chosen by the first two named
    before they enter upon the business of arbitration. The award and determination of the
    arbitrator or arbitrators or any two of the three arbitrators shall be binding upon the parties and
    their respective successors and permitted assigns. Arbitration shall be in accordance with the
    American Arbitration Association (AAA) rules in effect at the time, occur in the English language
    and all costs in respect of the above shall be borne equally between the parties.</p>
    
    <h5>FORCE MAJEURE</h5>
    <p>Gastops shall not be liable for any delay or failure in performance by reason of any cause
    beyond its reasonable control, including but not limited to acts of God, inclement weather, fire,
    explosions, floods, strikes, lock-out, work stoppages or other industrial dispute causing
    cessation, slowdown or interruption of work, accidents, war, riots, civil disturbances or embargo, 
    legislation, order, acts of government or other directive or regulation of any governmental
    authority, delay of subcontractors or suppliers, inability to obtain any necessary license, consent,
    work visa, work permit or other such permissions as stipulated by the jurisdiction in which the
    Customer-site resides, material shortages, or any other occurrences unforeseen (collectively
    “Force Majeure”). Gastops shall use commercially reasonable efforts to notify the Customer of
    the commencement and nature of such cause and the probable consequences thereof and
    shall use commercially reasonable efforts to render performance in a timely manner utilizing to
    such end all resources reasonably required in the circumstances, including obtaining supplies or
    services from other sources if same are reasonably available.</p>
    
    <h5>WAIVER</h5>
    <p>No claim or right arising out of a breach of this Agreement may be discharged in whole or in part
    by a waiver or renunciation unless supported by consideration and made in writing signed by
    the aggrieved party. Either party’s failure to enforce any provision hereof shall not be construed
    a waiver of a party’s right thereafter to enforce each and every such provision.</p>
    
    <h5>CONFIDENTIAL INFORMATION</h5>
    <p>The Customer shall hold confidential and shall not use, disclose or permit others to use any
    confidential information identified as such in writing or orally by Gastops or information which
    the Customer knows or ought to reasonably know is confidential, or proprietary.</p>
    
    <h5>ASSIGNMENT</h5>
    <p>Neither this Agreement nor any rights under this Agreement, other than monies due or to
    become due, shall be assigned or otherwise transferred by the Customer (by operation of law or
    otherwise) without the prior written consent of Gastops. This Agreement shall bind an inure to
    the benefit of the successors and permitted assigns of the parties.</p>
    
    <h5>SEVERABILITY</h5>
    <p>In the event that any of the terms of this Agreement, apart from payment, become or are
    declared to be illegal by any court of competent jurisdiction, such terms shall be null and void
    and shall be deemed deleted from this Agreement, but only to the extent that such term is
    illegal, it being the intent and agreement of the parties that the Agreement shall be deemed
    amended by modifying such term to the extent necessary to make it legal while preserving its
    intent or, if that is not possible, but substituting another term that is legal and achieves the same
    objective. All remaining terms of this Agreement shall remain in full effect.</p>
    
    <h5>RELATIONSHIP OF THE PARTIES</h5>
    <p>The relationship of the parties hereto is that of independent contractors. Neither party has the
    right nor authority to assume or create any obligation or responsibility on behalf of the other
    party. Nothing contained herein shall be construed as creating an agency or joint venture,
    consortium or partnership between the parties.</p>
    
    <h5>ENTIRE AGREEMENT</h5>
    <p>This Agreement constitutes the entire agreement between the parties hereto concerning the
    subject matter of this Agreement, apart from existing non-disclosure agreements, and there are
    no understandings, agreements, representations, conditions, warranties or other terms, express
    or implied, which are not specified herein. This Agreement may only be modified by a written
    document executed by authorized representatives of Gastops and the Customer.</p><style>
							h5 { font-weight:bold; font-size:13px; } 
							p { font-size:12px; } 
							</style>
							
							<p>The terms and conditions of sale contained herein (“Agreement”) constitute the entire
							agreement between Gastops Ltd. (“Gastops”) and you (“Customer”). Any terms that are
							inconsistent with this Agreement are hereby disclaimed by Gastops and no form of acceptance,
							except Gastops’ prior written acknowledgement shall constitute a valid acceptance thereof. The
							commencement of the manufacture or delivery of Products (as defined below) by Gastops is for
							the benefit of the Customer only and shall not be deemed an acceptance of the terms and
							conditions contained in the Customer’s order.</p>
							
							<h5>PRODUCTS</h5>
							<p>“Products” shall mean any product or service identified on either (a) Gastops’ proposals,
							quotations or order acknowledgements; or (b) current applicable price lists, in each case
							identifying the applicable Product specification. Gastops shall use best efforts to notify the
							Customer of any alterations to any Product deemed necessary to comply with specifications,
							safety standards or governmental regulations, to make a Product non-infringing with respect to
							any intellectual property or other proprietary interest or to otherwise improve a Product and
							such altered Product shall be deemed fully conforming.</p>
							
							<h5>ORDERS</h5>
							<p>Products shall be purchased by the Customer issuing a written purchase order signed by an
							authorized representative of the Customer, indicating the relevant Gastops’ proposal or
							quotation number, specific Products desired, quantity, total purchase price, shipping
							instructions (including Customer’s Employer Identification Number (EIN) or Federal Tax
							Identification Number (FTIN) for US shipments, Value Added Tax (VAT) number for shipments
							outside North America, ship to address, ship to contact name and phone number, method of
							shipping and carrier account information, billing address (if different from above), applicable tax
							exempt certifications and any other special instructions. All orders are subject to prior written
							acceptance by Gastops.</p>
							
							<h5>PRICES</h5>
							<p>Prices quoted are valid only for the transaction and period of time appearing on the document
							referencing this Agreement, unless otherwise specified by Gastops. Prices are exclusive of all
							taxes, handling or other charges, including insurance, brokerage fees, premiums, duties,
							transportation or special packaging (collectively “Charges”). Any Charge that Gastops may be
							liable to collect on behalf of any governmental authority or otherwise as a result of the sale, use
							or delivery of Products is the responsibility of the Customer, and if paid by Gastops shall be
							charged to the Customer as a separate item on the invoice and the Customer agrees to
							reimburse Gastops in respect thereof.</p>
							
							<h5>TERMS OF PAYMENT</h5>
							<p>Payment terms are thirty (30) days net from the date of shipment, or in the case of services,
							thirty (30) days net from the date of completion, unless otherwise specified by Gastops. Gastops
							reserves the right to obtain prior credit approval and request alternative payment terms, at its
							discretion. If at any time the Customer is delinquent in the payment of any invoice or is
							otherwise in breach of this Agreement, Gastops may, at its discretion withhold shipment or stop
							performance of services in respect of any order and may, at its option require the Customer to
							pre-pay for further shipments or performance. All payments not received when due shall be
							subject to an additional charge of one and a half percent (1.5%) per month (annual rate 18%) of
							the unpaid amount or the maximum rate permitted by law, which ever is less until the date of
							payment. The Customer grants to Gastops a security interest in Products (excluding services)
							purchased under this Agreement to secure payment for those Products purchased. If
							requested by Gastops, the Customer agrees to execute financing statements to perfect such
							security interest. There is no set-off right for the Customer.</p>
							
							<h5>PERFORMANCE AND SHIPPING</h5>
							<p>Gastops shall use best efforts to meet shipping and performance dates specified or
							communicated to the Customer. All Products (excluding services) shall be delivered EXW
							Gastops [Incoterms 2000], unless otherwise specified by Gastops. Title and risk of loss to
							Products (excluding services) shall pass from Gastops to the Customer upon delivery to the
							shipping carrier at the EXW point. All claims for shortage of Products ordered or for incorrect
							charges must be presented to Gastops within ten (10) business days after receipt by the
							Customer of the particular shipment of Products. Gastops shall not be liable for any damages or
							penalties for delay in delivery and the carrier shall not be deemed to be an agent of Gastops.
							Gastops may suspend its delivery obligation upon prior written notice to the Customer and
							without prejudice to any other rights of Gastops under this Agreement should a determination
							be made by Gastops that the financial condition of the Customer is such that the Customer’s
							obligations to Gastops with respect to payment or other material obligation may be
							compromised. Notwithstanding any other provision of this Agreement, Products shall be
							deemed accepted by the Customer upon first use (in the case of products) or upon completion
							(in the case of services), as applicable.</p>
							
							<p>With respect to services, Gastops represents: (a) that it will use qualified individuals with suitable
							training, experience and skill; and (b) that it will perform such services with due diligence in a
							manner consistent with industry standards applied to the performance of similar services.</p>
							
							<h5>PACKAGING</h5>
							<p>All Products shall be packaged for shipment to the Customer in accordance with Gastops’
							standard packaging practices and in conformity with applicable regulations.</p>
							
							<h5>CANCELLATION</h5>
							<p>Any cancellation, termination or issuance of a hold on any Customer order accepted by Gastops,
							by the Customer, shall be upon mutually agreeable terms that will compensate Gastops for any
							incurred costs, including but not limited to any work in progress or services performed and a
							reasonable allocation of general and administrative expenses relating to the Products.</p>
							
							<h5>WARRANTY</h5>
							<p>Gastops’ sole and exclusive obligation to the Customer for any Product (including without
							limitation integral parts thereof not of Gastops’ manufacture but excluding bulbs, fuses or parts
							of glass construction) and excluding Software (as defined and warranted below) and services (as
							warranted below) made by Gastops and sold hereunder are to repair returned Product or
							provide a replacement Product, at Gastops’ sole option, for any Product which has been
							returned to Gastops under the RMA procedure (as defined below) and which in the reasonable
							opinion of Gastops is determined to be defective in workmanship, material or not in compliance
							with the Gastops specification applicable to the Product and has in fact failed under normal use
							on or before one (1) year from the date of original installation or fifteen (15) months from the
							date of original shipment of the Product, which ever period is shorter. During the warranty
							period, any labor or travel costs associated with warranty services performed at the Customer’s
							facility or labor costs incurred by Gastops outside normal working hours shall be at the
							Customer’s sole expense. Replacement accessories or parts are warranted hereunder to be
							free from any material defect in workmanship and materials for a period of thirty (30) days from
							the original date of delivery. All Products, which are experimental products, prototypes or
							products used in field trials, are not warranted. All third parties’ products (including software)
							sold by Gastops carry only the original manufacturer’s warranty applicable to the Customer. Any
							Product repaired or replaced under warranty is only warranted for the period of time remaining
							in the original warranty for the Product. Gastops reserves the right, at its sole discretion, to
							issue a credit note for any defective Product as an alternative to repair or replacement. The
							warranty provided herein shall extend to any Product which has proved defective and has failed
							through normal use, but excludes and does not cover any Products or parts thereof which have
							been accidentally damaged, disassembled, modified, misused, used in applications which exceed
							the Product specifications or ratings, neglected, improperly installed by the Customer or
							otherwise abused or is used in hazardous activities. The Customer must claim under the
							warranty in writing not later than thirty (30) days after the claimed defect is discovered. Gastops
							warrants that services will be performed in a good and workmanlike manner in accordance with
							standards reasonably applicable to the services, and will reperform any services which Gastops
							determines are not in compliance with this warranty which the Customer advises Gastops of in
							writing, on or before thirty (30) days immediately following completion of the applicable service.
							The Customer must make all claims under these warranties and no claim will be accepted from
							any third party.</p>
							
							<h5>WORK ON CUSTOMER’S PREMISES</h5>
							<p>If the furnishing of any Products involves operations by Gastops on the premises of the
							Customer or one of its customers, Gastops shall take all necessary precautions to prevent the
							occurrence of any injury to persons or damage to property, including without limitation following
							any rules, procedures or other requirements. Gastops shall maintain Comprehensive General
							Liability (including contractual liability coverage) and Automobile Liability with suitable limits
							insuring the liabilities assumed above, as well as appropriate Worker’s Compensation Insurance
							as will protect the Customer from all claims under any applicable worker’s compensation acts.
							At the Customer’s request, Gastops shall furnish to Customer a Certificate of Insurance
							completed by its insurance carrier(s) certifying that the above insurance coverages are in effect.</p>
							
							<h5>RETURN MATERIAL AUTHORIZATION (RMA)</h5>
							<p>Gastops will only accept Products returned under the Gastops Return Material Authorization
							process (“RMA”). Customer shall obtain an RMA number from Gastops prior to returning any
							Product and return the Product prepaid and insured to Gastops’ facility. Any Product which has
							been returned to Gastops but which is found to meet the applicable specification for the
							Product and not defective in workmanship and material, may be subject to Gastops’ standard
							examination charge in effect at the time and may be charged to the Customer, at Gastops sole
							discretion.</p>
							
							<h5>SOFTWARE LICENSE AND WARRANTY</h5>
							<p>For software developed by Gastops and contained in any Product and all related documentation
							(collectively “Software”), Gastops does not transfer ownership (which shall remain solely with
							Gastops) but only grants to the Customer a perpetual, non-exclusive license to use the Software
							restricted to use related only to the operation of the single Product. Such license is transferable
							only with the transfer of ownership of the Product in which it is used. Except for making a backup
							copy or as permitted by law, the Customer shall not (a) modify, reproduce, copy, reverse
							engineer, decompile or disassemble all or any portion of the Software; (b) distribute, market,
							disclose rent, lease or create derivative works, or sublicense the use of the Software to any third
							party; or (c) permit or authorize anyone within the Customer’s reasonable control to do any acts
							in A or B above. Gastops warrants that the Software under normal use and service as originally
							delivered to the Customer will function substantially in accordance with the functional
							description set out in the Product specification and/or user manual supplied with the Software
							for a period of ninety (90) days from the date of shipment. Gastops’ sole liability and the
							Customer’s sole remedy for a breach of this Software warranty shall be Gastops’ good faith
							efforts to rectify the non-conformity or, if after repeated efforts Gastops is unable to rectify the
							non-conformity, Gastops shall accept return of the Product containing the non-conforming
							Software and shall refund to the Customer any amounts paid by the Customer in respect
							thereof. This warranty is void if failure of the Software has resulted from accident, misuse,
							abuse, misapplication or modification.</p>
							<p>EXCEPT FOR THE WARRANTIES STATED HEREIN, NO WARRANTY, CONDITION OR REPRESENTATION,
							EXPRESS, IMPLIED, ORAL OR STATUTORY, IS PROVIDED TO THE CUSTOMER OR ANY THIRD
							PARTY, INCLUDING WITHOUT LIMITATION ANY WARRANTY, CONDITION OR REPRESENTATION
							OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR SATISFACTORY QUALITY. THE
							CUSTOMER’S SOLE AND EXCLUSIVE REMEDIES HEREUNDER AND THE ONLY LIABILITY OF
							GASTOPS IS EXPRESSLY LIMITED TO THE TERMS OF THIS AGREEMENT.</p>
							
							<h5>INDEMNITY</h5>
							<p>Gastops shall indemnify and hold harmless the Customer, its directors, officers, employees,
							agents, representatives, successors and assigns against any and all claims, demands, judgments,
							damages or liabilities (including personal injury to or death of persons or property damage)
							arising from any negligent act or omission of Gastops, its agents, employees or subcontractors,
							except to the extent attributable to the sole and direct gross negligence of the Customer.
							Notwithstanding the above, in no event shall Gastops be liable to the Customer for any
							incidental, indirect, special, punitive, contingent or consequential damages and Gastops’ total
							cumulative liability to the Customer hereunder shall not exceed the amount paid to Gastops for
							Products furnished to the Customer from which such liability arose. The Customer shall
							indemnify and hold harmless Gastops, its directors, officers, employees, agents, representatives,
							successors and assigns against any and all claims, demands, judgments, damages or liabilities
							(including personal injury to or death of persons or property damage and third party claims)
							arising from or in connection with Customer’s use of any Product furnished by Gastops
							hereunder. Gastops shall defend any suit or proceeding brought against the Customer based
							on a claim that any Product manufactured or furnished by Gastops hereunder constitutes an
							infringement of any patent, provided the Customer provides all reasonable prior notice and all
							necessary authority and assistance to Gastops in defending such claim. If a determination is
							made by a court of competent jurisdiction that Gastops’ Product infringes any third party
							intellectual property rights, Gastops shall either (a) procure for the Customer the right to
							continue using such Product; (b) replace the same modified so that it becomes non-infringing; or
							(c) refund an appropriate portion of the purchase price, in each case at its sole discretion and
							expense. Customer agrees that the forgoing shall constitute Gastops sole and exclusive liability
							to Customer for patent infringement and Gastops shall have no further obligations to Customer
							other than as described herein.</p>
							
							<h5>EXPORT RESTRICTIONS</h5>
							<p>Both parties shall comply with all applicable export laws and regulations. The Customer shall
							not transmit, export or re-export, direct or indirectly, separately or as part of any system, the
							Product or any technical data received from Gastops, without first obtaining a license as may be
							required under such regulations. The Customer shall indemnify and hold harmless Gastops
							from and against any and all damages sustained by the Customer arising from or in connection
							with any breach of this section.</p>
							
							<h5>COMPLIANCE WITH LAWS</h5>
							<p>Gastops warrants that it shall comply with the applicable provisions of all Federal, State, or local
							laws or ordinances and all lawful orders, rules and regulations issued thereunder as they may
							pertain to Gastops performance under this Agreement.</p>
							
							<h5>GOVERNING LAW</h5>
							<p>This Agreement shall be governed by and construed in accordance with the applicable laws of
							the State of New York, excluding conflict of law rules. The parties specifically disclaim the
							application of the United Nations Convention on Contracts for the International Sale of Goods.</p>
							
							<h5>ARBITRATION</h5>
							<p>All matters in difference between the parties in relation to this Agreement may be referred to
							the arbitration of a single arbitrator, if the parties agree upon one, otherwise to three
							arbitrators, one to be appointed by each party and a third to be chosen by the first two named
							before they enter upon the business of arbitration. The award and determination of the
							arbitrator or arbitrators or any two of the three arbitrators shall be binding upon the parties and
							their respective successors and permitted assigns. Arbitration shall be in accordance with the
							American Arbitration Association (AAA) rules in effect at the time, occur in the English language
							and all costs in respect of the above shall be borne equally between the parties.</p>
							
							<h5>FORCE MAJEURE</h5>
							<p>Gastops shall not be liable for any delay or failure in performance by reason of any cause
							beyond its reasonable control, including but not limited to acts of God, inclement weather, fire,
							explosions, floods, strikes, lock-out, work stoppages or other industrial dispute causing
							cessation, slowdown or interruption of work, accidents, war, riots, civil disturbances or embargo, 
							legislation, order, acts of government or other directive or regulation of any governmental
							authority, delay of subcontractors or suppliers, inability to obtain any necessary license, consent,
							work visa, work permit or other such permissions as stipulated by the jurisdiction in which the
							Customer-site resides, material shortages, or any other occurrences unforeseen (collectively
							“Force Majeure”). Gastops shall use commercially reasonable efforts to notify the Customer of
							the commencement and nature of such cause and the probable consequences thereof and
							shall use commercially reasonable efforts to render performance in a timely manner utilizing to
							such end all resources reasonably required in the circumstances, including obtaining supplies or
							services from other sources if same are reasonably available.</p>
							
							<h5>WAIVER</h5>
							<p>No claim or right arising out of a breach of this Agreement may be discharged in whole or in part
							by a waiver or renunciation unless supported by consideration and made in writing signed by
							the aggrieved party. Either party’s failure to enforce any provision hereof shall not be construed
							a waiver of a party’s right thereafter to enforce each and every such provision.</p>
							
							<h5>CONFIDENTIAL INFORMATION</h5>
							<p>The Customer shall hold confidential and shall not use, disclose or permit others to use any
							confidential information identified as such in writing or orally by Gastops or information which
							the Customer knows or ought to reasonably know is confidential, or proprietary.</p>
							
							<h5>ASSIGNMENT</h5>
							<p>Neither this Agreement nor any rights under this Agreement, other than monies due or to
							become due, shall be assigned or otherwise transferred by the Customer (by operation of law or
							otherwise) without the prior written consent of Gastops. This Agreement shall bind an inure to
							the benefit of the successors and permitted assigns of the parties.</p>
							
							<h5>SEVERABILITY</h5>
							<p>In the event that any of the terms of this Agreement, apart from payment, become or are
							declared to be illegal by any court of competent jurisdiction, such terms shall be null and void
							and shall be deemed deleted from this Agreement, but only to the extent that such term is
							illegal, it being the intent and agreement of the parties that the Agreement shall be deemed
							amended by modifying such term to the extent necessary to make it legal while preserving its
							intent or, if that is not possible, but substituting another term that is legal and achieves the same
							objective. All remaining terms of this Agreement shall remain in full effect.</p>
							
							<h5>RELATIONSHIP OF THE PARTIES</h5>
							<p>The relationship of the parties hereto is that of independent contractors. Neither party has the
							right nor authority to assume or create any obligation or responsibility on behalf of the other
							party. Nothing contained herein shall be construed as creating an agency or joint venture,
							consortium or partnership between the parties.</p>
							
							<h5>ENTIRE AGREEMENT</h5>
							<p>This Agreement constitutes the entire agreement between the parties hereto concerning the
							subject matter of this Agreement, apart from existing non-disclosure agreements, and there are
							no understandings, agreements, representations, conditions, warranties or other terms, express
							or implied, which are not specified herein. This Agreement may only be modified by a written
							document executed by authorized representatives of Gastops and the Customer.</p>
                        </div>