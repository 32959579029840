import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { TermOfServicesComponent } from './components/termsOfServices/termsOfServices.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AuthGuardService } from './route-guard';
import { DeviceQueryService } from './deviceQuery.service';
import { AdminauthGuardService } from './adminModules/admin-route-guard';

@NgModule({
  declarations: [
    AppComponent,
    TermOfServicesComponent,
    PrivacyComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, CoreModule.forRoot()],
  providers: [AuthGuardService, AdminauthGuardService, DeviceQueryService],
  bootstrap: [AppComponent],
})
export class AppModule {}
