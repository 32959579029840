import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute, CanLoad, Router } from '@angular/router';
import { AppCookieService } from './modules/core/services/cookie-service.service';

@Injectable()
export class AuthGuardService implements CanLoad {
  constructor(
    private router: Router,
    public _appCookieService: AppCookieService,
    private _actvatedZRoute: ActivatedRoute
  ) {}

  canLoad(route: Route): boolean {
    
    const role = this._appCookieService.getCookieData().role;

    const accesstoken = this._appCookieService.getCookieData().accesstoken;

    if (accesstoken) {
      if (parseInt(role) == 0 || parseInt(role) == 2 || parseInt(role) == 3) {
        this.checkExternalUrl();
        return true;
      } else if (role != '') {
        setTimeout(() => {
          this._appCookieService.clearCookieData();
          this.router.navigate(['login']);
          alert('You are not authorised to visit this page');
          return false;
        }, 0);
      }
    } else {
      let pieces = window.location.href.split('#/')
      this._appCookieService.clearCookieData();     
      if ( pieces.length > 1 ) {
        if (pieces[1] != 'login') {
          this._appCookieService.setCookieData(
            'routeUrl',
            JSON.stringify(pieces[1])
          );
        }
      }
      this.router.navigate(['login']);
    }
  }

  checkExternalUrl() {
    const r1 = window.location.href;
    if (r1 && r1 != '') {
      const routeUrl = r1;
      const splitResult = routeUrl.split(';');
      if (splitResult.length == 3) {
        const childSplitResult = splitResult[2].split('=');
        if (childSplitResult.length > 0 && childSplitResult[0] == 'orgId') {
          this._appCookieService.setCookieData(
            'routeUrl',
            JSON.stringify(window.location.href.split('#/')[1])
          );
        }
      }
    }
  }
}
