import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AppCookieService {
  constructor(private _cookieService: CookieService) {}

  getCookieData() {
    return {
      accesstoken: this._cookieService.get('accesstoken'),
      jwt: this._cookieService.get('jwt'),
      refreshtoken: this._cookieService.get('refreshtoken'),
      username: this._cookieService.get('username'),
      userId: this._cookieService.get('userId'),
      role: this._cookieService.get('role'),
      orgId: this._cookieService.get('orgId'),
      phoneIsVerified: this._cookieService.get('phoneIsVerified'),
      routeUrl: this._cookieService.get('routeUrl'),
      remember: this._cookieService.get('remember')
    };
  }
  checkCookie(key: string) {
    return this._cookieService.check(key);
  }

  setCookieData(key: string, value: string) {
    this._cookieService.set(key, value);
  }

  clearCookieData() {
    const tt = this.getCookieData().routeUrl;
    this._cookieService.deleteAll();
    this.setCookieData('routeUrl', tt);
  }

  clearSpecificCookieData(key) {
    this._cookieService.delete(key);
  }
}
