export const environment = {
  production: true,
  serverDomain:
    'https://6klkq5k0gc.execute-api.us-east-2.amazonaws.com/api/v1/',
  awsDomain:
    'https://6klkq5k0gc.execute-api.us-east-2.amazonaws.com/api/v1/',
  generalUpload:
    'https://remote-portal-assets-repository.s3.us-east-2.amazonaws.com/',
  equipmentEventRepository:
    'https://remote-portal-assets-data-repository.s3.us-east-2.amazonaws.com/',
  assetsRepository:
    'https://remote-portal-assets-repository.s3.us-east-2.amazonaws.com',
  assetsRepository2:
    'https://remote-portal-assets-data-repository.s3.us-east-2.amazonaws.com',
  dataRepository: 'remote-portal-equipment-conditions-repository',
  dataSample: 'remote-gastops-portal-data-samples',
  dataEquip: 'https://remote-portal-equipment-data-repository.s3.amazonaws.com',
  mapKey: '9ileJUhgc9ChkgZheuhVqsP24l75bx9n',
  assetsEqui: 'https://remote-portal-assets-repository.s3.amazonaws.com',
  fileSize: 10000000
};
