<style>
h5 { font-weight:bold; font-size:13px; } 
p { font-size:12px; } 
.page-height { height:100vh; border:none; padding:40px; }
</style>
<div class="grid-container page-height">
    
    <h4 class="nameStyle">Privacy</h4>

    <h3 class="text-center mb-3">Gastops is committed to controlling the collection, use and disclosure of the personal information provided by its customers.</h3>

    <p class="text-center">THIS PRIVACY POLICY HAS BEEN ADOPTED TO ENSURE THE ACCURACY, CONFIDENTIALITY AND INTEGRITY OF YOUR PERSONAL INFORMATION AND TO INFORM YOU OF OUR PRACTICES CONCERNING THE COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION COLLECTED BY GASTOPS.</p>
    
    <h4>Personal Information</h4>

    <p>“Personal Information” refers to any information, recorded in any form, about an identifiable individual, or an individual whose identity may be inferred or determined from the information.</p>

    <h4>Collection and Use of Personal Information</h4>

    <p>Gastops does not sell, trade, or exchange for consideration any Personal Information it has obtained. Unless permitted by law, no Personal Information is collected about an individual, without first obtaining the consent of the individual to the collection, use and disclosure of that information. When you visit our website, your IP address is recorded for system administration and internal tracking purposes only and is not linked to anything personally identifiable.</p>

    <h4>Disclosure</h4>

    <p>To enable us to more efficiently provide the products and services you have requested from us, Gastops may share your Personal Information with selected third parties that are acting on our behalf as our agents, suppliers or service providers. Such third parties are provided only with the information that is necessary in the circumstances. The Personal Information may only be used for the purpose stipulated and is subject to strict terms of confidentiality.</p>

    <h4>Accuracy</h4>

    <p>Gastops is committed to ensuring that the Personal Information about you is accurate, current and complete as necessary for the purposes for which Gastops uses that information. In the event you have questions about the accuracy of factual information we have collected about you, you will have access to that information in order to verify and update it. If we have disclosed inaccurate information about you to a third party, we will be happy to contact the third party in order to correct the information.</p>

    <h4>Security</h4>

    <p>Gastops is committed to protecting your privacy. Security measures have been adopted to protect your Personal Information against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. Our employees have been trained to respect your privacy at all times and those employees with access to your Personal Information shall hold it in strict confidence and in accordance with applicable law.</p>

    <h4>Access</h4>

    <p>If you have any questions about the collection, use or disclosure of your Personal Information by Gastops, you are encouraged to contact our Chief Privacy Officer who can confirm the source of the information, allow you access to your Personal Information and identify the third parties to which it has been disclosed.</p>

    <h4>Compliance</h4>

    <p>Gastops’ privacy policy is compliant with applicable federal and provincial legislation, including without limitation the Personal Information Protection and Electronic Documents Act.</p>

</div>