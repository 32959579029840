import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'termofservices',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit, OnDestroy {
  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
